import React from 'react';
import styled from 'styled-components';

import Layout from '../components/Layout';
import SEO from '../components/Seo';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Wrapper>
      <h1>ΔΕΝ ΒΡΕΘΗΚΕ</h1>
      <p>Επ, αυτή η σελίδα δεν υπάρχει. Δοκίμασε κάποια από τις κατηγορίες μας :)</p>
    </Wrapper>
  </Layout>
);

export default NotFoundPage;
